import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PageContent from '../components/page-content'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
        <PageContent title={frontmatter.title}>{html}</PageContent>
    </Layout>
  )
}

export const pageQuery = graphql`
  query pageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        event
      }
      timeToRead
    }
  }
`